<template>
  <div class="functional-navigation">
    <div @click="show = !show" class="show cursor-hover">{{ currentLink.name }}
      <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L9 9L17 1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="hide" v-if="show" @click="show = !show">
      <router-link v-bind:key="value" v-for="(key, value) in data" class="cursor-hover" :to="key.link">{{ key.name }}
        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.7793 1.0127L7.30016 13.4793L1.00569 7.5345" stroke="black" stroke-width="2" stroke-linecap="round"/>
        </svg>

      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "functional-navigation",
  props: {
    currentLink: Object
  },
  data() {
    return {
      show: false,
      data: [
        {
          link: "/privacy-policy",
          name: "Privacy Policy"
        },
        {
          link: "/data-processing-agreement",
          name: "Data Processing Agreement"
        },
        {
          link: "/code-of-conduct",
          name: "Code of Conduct"
        },
        {
          link: "/term-of-service",
          name: "Term of Service"
        },
      ],
    };
  },
}

</script>

<style scoped lang="scss">
.functional-navigation {
  display: none;
  max-width: 320px;
  width: 100%;
  border-bottom: 1px solid #92A1AE;
  position: relative;

  .show {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    color: var(--text-primary-color-case);
    width: 100%;
    padding: 4px 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      path {
        stroke: var(--text-primary-color-case);
      }
    }
  }

  .hide {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid var(--text-primary-color-case);
    background: var(--background-color-primary);

    flex-direction: column;

    a {
      text-align: left;
      padding: 19px 20px;
      font-family: 'Montserrat';
      font-size: 16px;
      line-height: 24px;

      letter-spacing: 0.02em;

      color: var(--text-primary-color-case);

      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all .2s ease;

      &.router-link-exact-active {
        background: #29F6D9;
        color: #000000;

        svg {
          display: block;
        }
      }

      svg {
        display: none;

        path {
        }
      }

      @media screen and (min-width: 1023px) {
        &:hover {
          background: #29F6D9;
          color: #000000 !important;

          svg {
            display: block;
          }
        }
      }

      &:active {
        background: #29F6D9;
        color: #000000 !important;

        svg {
          display: block;
        }
      }
    }
  }


  @media screen and (max-width: 920px) {
    display: flex;
  }
}
</style>
